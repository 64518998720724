// Dependencies
import React, { FC } from "react";
import styled from "styled-components";

// Components
import { Link } from "gatsby";

interface ButtonProps {
  linkTo: string;
  content: string;
}

const Button: FC<ButtonProps> = ({ linkTo, content }) => {
  const clearActive = linkTo => {
    const active = document.querySelector(".active");
    if (linkTo === "/dlafirm") {
      active.classList.remove("active");
    }
  };

  if (content === "WKRÓTCE DOSTĘPNE")
    return <StyledDisabledLink>{content}</StyledDisabledLink>;

  return (
    <StyledLink onClick={() => clearActive(linkTo)} to={linkTo}>
      {content}
    </StyledLink>
  );
};

export default Button;

const StyledDisabledLink = styled.div`
  display: inline-block;
  font-size: ${props => props.theme.fontSizes[1]}px;
  border: 2px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  padding: ${props => props.theme.space[3] + 4}px
    ${props => props.theme.space[6]}px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  cursor: not-allowed;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  font-size: ${props => props.theme.fontSizes[1]}px;
  border: 2px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  padding: ${props => props.theme.space[3] + 4}px
    ${props => props.theme.space[6]}px;
  position: relative;
  overflow: hidden;
  transition: 0.4s ease;
  z-index: 2;
  @media screen and (max-width: ${props => props.theme.breakpoints[2]}) {
    width: 100%;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints[3]}) {
    &::after {
      content: "";
      width: 0;
      height: 100%;
      transform: skew(-25deg);
      background: ${props => props.theme.colors.primary};
      position: absolute;
      top: 0;
      left: -10%;
      transition: 0.3s ease;
      z-index: -1;
    }
    &:hover {
      color: ${props => props.theme.colors.white};
      &::after {
        width: 120%;
      }
    }
  }
`;
