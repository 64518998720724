// Dependencies
import React from 'react';
import styled from 'styled-components';

// Components
import Button from '../components/button';

interface Props {
  header: string;
  content: string;
  linkTo: string;
  label: string;
}

const OfferBox: React.FC<Props> = ({ header, content, linkTo, label }) => {
  return (
    <Box>
      <FlexWrapper>
        <div>
          <BoxHeader>{header}</BoxHeader>
          <TextContent>{content}</TextContent>
        </div>

        <Button linkTo={linkTo} content={label} />
      </FlexWrapper>
    </Box>
  );
};

export default OfferBox;

const Box = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: white;
  display: flex;
  margin-bottom: ${(props) => props.theme.space[4]}px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    max-width: 32%;
    margin-bottom: 0;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints[3]}) {
    max-width: 300px;
  }
`;

const BoxHeader = styled.h3`
  font-size: ${(props) => props.theme.fontSizes[4]}px;
  margin: 0 0 ${(props) => props.theme.space[4]}px 0;
  font-weight: 500;
`;

const TextContent = styled.p`
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  margin-bottom: ${(props) => props.theme.space[4]}px;
`;

const FlexWrapper = styled.div`
  padding: ${(props) => props.theme.space[5]}px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: ${(props) => props.theme.space[6]}px;
  }
`;
