// Dependencies
import React, { Fragment, useRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { graphql } from 'gatsby';

// Components
import SEO from '../components/seo';
import OfferMain from '../components/offer-main';
import GridMain from '../components/grid-main';
import OpeningImage from '../components/opening-image';
import { SectionHeader } from '../components/offer-main';
import Button from '../components/button';
import useOnScreen from '../components/useOnScreen';
const Carousel = React.lazy(() => import('../components/carousel'));
const Map = React.lazy(() => import('../components/map'));
const WeaponCarousel = React.lazy(() => import('../components/weaponCarousel'));

let mapRendered = false;
let carouselRendered = false;
let weaponCarouselRendered = false;

const IndexPage = ({ data }: any) => {
  const isSSR = typeof window === 'undefined';
  const weapons = data.allDataJson.edges[0].node.homePageCarouselWeapons;

  const mapRef = useRef();
  const carouselRef = useRef();
  const weaponCarouselRef = useRef();

  const mapOnScreen = useOnScreen(mapRef, '500px');
  const carouselOnScreen = useOnScreen(carouselRef, '500px');
  const weaponCarouselOnScreen = useOnScreen(weaponCarouselRef, '500px');

  const filteredWeapons =
    data.cms.weapons.length > 0
      ? data.cms.weapons.filter((e) => weapons.includes(e.weaponId))
      : [];

  const { text, phoneNumber } = data.cms.homePage.epidemyDisclaimer;
  const carouselItems = data.cms.homePage.carousel.carouselItems;
  const carouselTitle = data.cms.homePage.carousel.title;

  return (
    <Fragment>
      <Fade>
        <OpeningImage />
        <Container>
          <EpidemyDisclaimer>
            {text}
            <SpecialSpan>{phoneNumber}</SpecialSpan>
          </EpidemyDisclaimer>
        </Container>
        <Container>
          <OfferMain offerSection={data.cms.homePage.offerSection} />
          <GridMain grid={data.cms.homePage.grid} />
        </Container>
      </Fade>
      <SEO />

      <div>
        <SectionHeader ref={carouselRef}>{carouselTitle}</SectionHeader>
        {!isSSR && (carouselOnScreen || carouselRendered) && (
          <React.Suspense fallback={<div />}>
            {(carouselRendered = true)}
            <Carousel carouselItems={carouselItems} />
          </React.Suspense>
        )}
        <ArsenalMain>
          <SectionHeader>Nasz arsenał</SectionHeader>
          <Container ref={weaponCarouselRef}>
            {(weaponCarouselOnScreen || weaponCarouselRendered) && (
              <React.Suspense fallback={<div />}>
                {(weaponCarouselRendered = true)}
                <WeaponCarousel weaponsArray={filteredWeapons} />
              </React.Suspense>
            )}
          </Container>
          <FlexWrapper>
            <Button linkTo={'/arsenal'} content={'zobacz nasz arsenał'} />
          </FlexWrapper>
        </ArsenalMain>
        <div ref={mapRef}>
          {(mapOnScreen || mapRendered) && (
            <React.Suspense fallback={<div />}>
              {(mapRendered = true)}
              <Map />
            </React.Suspense>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default IndexPage;

export const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.containerWidth}px;
  margin: 0 auto;
  margin-top: -${(props) => props.theme.space[6]}px;
  padding: 0 ${(props) => props.theme.space[4]}px;
`;

const ArsenalMain = styled.div`
  margin-bottom: ${(props) => props.theme.space[7]}px;
  margin-top: ${(props) => props.theme.space[7]}px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-bottom: ${(props) => props.theme.space[10]}px;
    margin-top: ${(props) => props.theme.space[10]}px;
  }
`;

const EpidemyDisclaimer = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 50px;
  max-width: 600px;
  margin: 0 auto 50px auto;
`;

const SpecialSpan = styled.span`
  color: ${(props) => props.theme.colors.primary};
  margin-left: 5px;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 16px;
`;

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          homePageCarouselWeapons
        }
      }
    }
    cms {
      homePage(where: { type: "main" }) {
        offerSection {
          items
          title
        }
        grid {
          title
          gridItems {
            text
            icon {
              url
            }
          }
        }
        epidemyDisclaimer {
          text
          phoneNumber
        }
        carousel {
          title
          carouselItems {
            text
            image {
              handle
              width
              height
            }
          }
        }
      }
      weapons(stage: PUBLISHED, orderBy: weaponId_ASC) {
        slug
        name
        weaponId
        image {
          handle
          width
          height
        }
      }
    }
  }
`;
