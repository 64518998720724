// Dependencies
import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';
import styled from 'styled-components';

// Components
import { SectionHeader } from './offer-main';

const IconsGridMainSection = ({ grid }: any) => {
  const { title, gridItems } = grid;

  return (
    <Fragment>
      <SectionHeader>{title}</SectionHeader>
      <GridWrapper>
        {gridItems.map((cell, index) => {
          return (
            <Cell key={index}>
              <StyledImg src={cell.icon.url} />
              <CellText>{cell.text}</CellText>
            </Cell>
          );
        })}
      </GridWrapper>
    </Fragment>
  );
};

export default IconsGridMainSection;

const StyledImg = styled.img`
  width: 52px;
  height: 52px;
`;

const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${(props) => props.theme.space[5]}px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[0]}) {
    width: 50%;
    margin-bottom: ${(props) => props.theme.space[7]}px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-bottom: ${(props) => props.theme.space[9]}px;
    width: 32%;
    justify-content: flex-start;
  }
`;

const CellText = styled.p`
  padding-right: ${(props) => props.theme.space[2]}px;
  color: ${(props) => props.theme.colors.white};

  margin: 0 0 0 ${(props) => props.theme.space[3]}px;
  font-size: ${(props) => props.theme.fontSizes[4]}px;
`;
