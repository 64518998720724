// Dependencies
import { useState, useEffect } from "react";

// Hook
function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    if (typeof window === "object") {
      return {
        width: isClient ? window.innerWidth : 0,
        height: isClient ? window.innerHeight : 0
      };
    }
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }
    if (isClient) {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default useWindowSize;
