import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  right?: Boolean;
  black?: Boolean;
  small?: boolean;
}

const Chevron: FC<Props> = ({ right, black, small }) => {
  return (
    <StyledSVG
      right={right}
      black={black}
      small={small}
      x="0px"
      y="0px"
      viewBox="0 0 185.343 185.343"
    >
      <g>
        <g>
          <path
            d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175
			l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934
			c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default Chevron;

const StyledSVG = styled.svg<{
  right: Boolean;
  black: Boolean;
  small: boolean;
}>`
  width: ${(props) => (props.small ? 14 : 48)}px;
  height: ${(props) => (props.small ? 14 : 48)}px;
  fill: ${(props) =>
    props.black ? props.theme.colors.black : props.theme.colors.white};
  transform: rotate(${(props) => (props.right ? 0 : "180deg")});
  @media screen and (min-width: ${(props) => props.theme.breakpoints[3]}) {
    width: ${(props) => (props.small ? 16 : 60)}px;
    height: ${(props) => (props.small ? 16 : 80)}px;
  }
`;
