// Dependencies
import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';
import { convertReactPropstoHtmlAttributes } from 'react-helmet/lib/HelmetUtils';
import styled from 'styled-components';

// Components
import OfferBox from './offer-box';

const OfferMain = ({ offerSection }: any) => {
  const { title, items } = offerSection;

  return (
    <Fragment>
      <SectionHeader>{title}</SectionHeader>
      <Container>
        {items.map((offer) => {
          return (
            <OfferBox
              key={offer.header}
              header={offer.header}
              content={offer.content}
              linkTo={offer.linkTo}
              label={offer.label}
            ></OfferBox>
          );
        })}
      </Container>
    </Fragment>
  );
};

export default OfferMain;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.space[6]}px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[0]}) {
    margin-bottom: ${(props) => props.theme.space[7]}px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: row;
    margin-bottom: ${(props) => props.theme.space[10]}px;
  }
`;

export const SectionHeader = styled.h2`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes[8]}px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: ${(props) => props.theme.space[7]}px;
`;
