// Dependencies
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../theme';
import useWindowSize from './useWindowSize';

const OpeningImage = () => {
  const [videoShown, changeVideoShown] = useState(false);
  const windowSize = useWindowSize();
  const windowWidth = windowSize && windowSize.width;
  const breakpoint = theme.breakpoints[3];
  useEffect(() => {
    if (windowWidth > parseInt(breakpoint)) {
      return changeVideoShown(true);
    }
    return changeVideoShown(false);
  }, [windowWidth, breakpoint]);

  const data = useStaticQuery(graphql`
    query {
      cms {
        openingImages(stage: PUBLISHED) {
          text
          youtubeVideo
        }
      }
    }
  `);

  const { text, youtubeVideo } = data.cms.openingImages[0];

  return (
    <OpeningWrapper>
      <VideoWrapper>
        {videoShown ? (
          <React.Suspense fallback={<div />}>
            <JumboVideo
              width="640"
              height="360"
              src={youtubeVideo}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
            />
          </React.Suspense>
        ) : null}
        <Overlay>
          <OverlayText>{text}</OverlayText>
        </Overlay>
      </VideoWrapper>
      <JumboImage className="opening-image" />
      <Spacer />
    </OpeningWrapper>
  );
};

export default OpeningImage;

const Overlay = styled.div`
  background-color: rgba(20, 20, 20, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OverlayText = styled.div`
  z-index: 101;
  font-size: 5vw;
  font-weight: 300;
  font-family: 'Russo One', sans-serif;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  @media (min-aspect-ratio: 16/9) {
    height: 300%;
    top: -100%;
  }
  @media (max-aspect-ratio: 16/9) {
    width: 300%;
    left: -100%;
  }
  @media (min-width: 1285px) and (max-width: 2282px) {
    height: 180%;
    top: -40%;
  }
`;

const JumboVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  @media screen and (max-width: ${(props) => props.theme.breakpoints[3]}) {
    display: none;
  }
`;

const JumboImage = styled.div`
  height: 400px;
  background-size: cover;
  background-position: center;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[3]}) {
    height: 700px;
  }
`;

const OpeningWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: ${(props) => props.theme.space[9]}px;
`;

const Spacer = styled.div`
  height: 10px;
  background-color: ${(props) => props.theme.colors.primary};
  position: relative;
`;
